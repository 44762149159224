import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../../generic-page.scss';
import '../index.scss';
import './afk-guide.scss';
import { AFKCharacter } from '../../../modules/afk/common/components/afk-character';
import { faSkull } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Col, Row, Tab, Tabs } from 'react-bootstrap';
import { AFKItem } from '../../../modules/afk/common/components/afk-item';
import { YoutubeEmbed } from '../../../modules/common/components/youtube-embed';

const AFKGuidesDreamCroaker: React.FC = () => {
  return (
    <DashboardLayout
      className={'generic-page afk-journey afk-guide'}
      game="afk"
    >
      <ul className="breadcrumb">
        <li>
          <Link to="/afk-journey/">AFK Journey</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/afk-journey/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Dream Realm - King Croaker</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/afk/categories/category_croaker.jpg"
            alt="Guides"
          />
        </div>
        <div className="page-details">
          <h1>AFK Journey King Croaker</h1>
          <h2>
            Dream Realm King Croaker guide that explains the boss mechanics and
            how to beat it!
          </h2>
          <p>
            Last updated: <strong>13/09/2024</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content">
        <SectionHeader title="King Croaker " />
        <StaticImage
          src="../../../images/afk/generic/boss_croaker.webp"
          alt="Guides"
        />
        <p>
          <strong>King Croaker</strong> is one of the 8 available Dream Realm
          bosses you will encounter in AFK Journey. In this guide we will go
          over the boss mechanics and the best characters and teams to use
          against him.
        </p>
        <SectionHeader title="What’s the meta team?" />
        <p>
          Realistically speaking,{' '}
          <strong>
            your meta team will depend on your specific box, and your account
            focus
          </strong>
          , so use your highest rarity DPS carry characters, along with whoever
          can best boost their damage.{' '}
          <strong className="gold">
            Try running Dual carry, as currently there is no way to boost a
            Hypercarry’s damage high enough to make it worth running only one
          </strong>
          . Keep faction bonus in mind but don’t run suboptimal characters just
          to force it.
        </p>
        <p>
          Still, below you can find a few example teams. Big thanks to{' '}
          <StaticImage
            src="../../../images/afk/icons/afkanalytica.png"
            alt="AFK Analytica"
          />{' '}
          <strong className="yellow">AFK Analytica</strong> for the team
          building tool. You can find their website{' '}
          <a
            href="https://www.afkanalytica.com/"
            target="_blank"
            rel="noreferrer"
          >
            here
          </a>{' '}
          and their discord{' '}
          <a
            href="https://www.discord.gg/analytica"
            target="_blank"
            rel="noreferrer"
          >
            here
          </a>
          .
        </p>
        <SectionHeader title="Season 2 Teams (Pre-Endless)" />
        <p>
          In Season 2 it will take a few weeks to unlock Endless mode and due to
          how True Damage works pre-Endless (it being weaker than normal
          damage), the meta will most likely look slightly different compared to
          the established Season 1 teams. Also, Season 2 comes with brand new
          Artifacts that also can have a big impact on the meta teams.
        </p>
        <p>
          We will add the teams that work best for us every rotation here soon!
        </p>
        <SectionHeader title="Season 1 Teams (Endless)" />
        <Tabs
          defaultActiveKey="Team1"
          transition={false}
          id="boss-tabs"
          className="nav-tabs"
        >
          <Tab
            eventKey="Team1"
            title={
              <>
                <FontAwesomeIcon icon={faSkull} width="18" />
                Team #1 - Best Team (Whale) Endless
              </>
            }
            className="with-bg"
          >
            <h5 className="endless">
              <FontAwesomeIcon icon={faSkull} width="18" /> Team #1 - Best Team
              (Whale) Endless
            </h5>
            <StaticImage
              src="../../../images/afk/dream/croaker_1.webp"
              alt="Guides"
            />
            <p>Character information:</p>
            <ul className="bigger-margin">
              <li>
                <AFKCharacter mode="inline" slug="talene" enablePopover /> is
                the main carry,
              </li>
              <li>
                <AFKCharacter mode="inline" slug="phraesto" enablePopover />{' '}
                acts as a tank, secondary carry, and provides energy,
              </li>
              <li>
                <AFKCharacter mode="inline" slug="ludovic" enablePopover /> is
                the secondary carry and healer,
              </li>
              <ul>
                <li>
                  <AFKCharacter mode="inline" slug="lily-may" enablePopover />{' '}
                  can be used as an alternative to Ludovic.
                </li>
              </ul>
              <li>
                <AFKCharacter mode="inline" slug="thoran" enablePopover /> acts
                as a tank and debuffer
              </li>
              <ul>
                <li>
                  <AFKCharacter mode="inline" slug="mikola" enablePopover />{' '}
                  Mikola can be used as an alternative to Thoran
                </li>
              </ul>
              <li>
                <AFKCharacter
                  mode="inline"
                  slug="smokey-and-meerky"
                  enablePopover
                />{' '}
                acts as healer, buffer and secondary damage dealer,
              </li>
              <li>Artifacts:</li>
              <ul>
                <li>
                  <AFKItem name="Evocation Spell" /> best in slot for the
                  defensive and offensive buffs.
                </li>
              </ul>
            </ul>
          </Tab>
          <Tab
            eventKey="Team2"
            title={
              <>
                <FontAwesomeIcon icon={faSkull} width="18" />
                Team #2 - Second Best Team (Whale, No Ludovic) Endless
              </>
            }
            className="with-bg"
          >
            <h5 className="endless">
              <FontAwesomeIcon icon={faSkull} width="18" /> Team #2 - Second
              Best Team (Whale, No Ludovic) Endless
            </h5>
            <StaticImage
              src="../../../images/afk/dream/croaker_2.webp"
              alt="Guides"
            />
            <p>
              This team is made for the <strong>Endless difficulty</strong>{' '}
              where the boss scaling is different and True Damage is again king.
            </p>
            <p>Character information:</p>
            <ul className="bigger-margin">
              <li>
                <AFKCharacter mode="inline" slug="talene" enablePopover /> is
                the main carry,
              </li>
              <li>
                <AFKCharacter mode="inline" slug="phraesto" enablePopover />{' '}
                acts as a tank, secondary carry, and provides energy,
              </li>
              <li>
                <AFKCharacter mode="inline" slug="reinier" enablePopover /> acts
                as buffer and debuffer (<strong>Mythic+</strong> required),
              </li>
              <li>
                <AFKCharacter mode="inline" slug="thoran" enablePopover /> acts
                as a tank and debuffer
              </li>
              <li>
                <AFKCharacter
                  mode="inline"
                  slug="smokey-and-meerky"
                  enablePopover
                />{' '}
                acts as healer, buffer and secondary damage dealer,
              </li>
              <li>Artifacts:</li>
              <ul>
                <li>
                  <AFKItem name="Evocation Spell" /> best in slot for the
                  defensive and offensive buffs.
                </li>
              </ul>
            </ul>
          </Tab>
          <Tab
            eventKey="Team3"
            title={
              <>
                <FontAwesomeIcon icon={faSkull} width="18" />
                Team #3 - Alt Team Endless
              </>
            }
            className="with-bg"
          >
            <h5 className="endless">
              <FontAwesomeIcon icon={faSkull} width="18" /> Team #3 - Alt Team
              Endless
            </h5>
            <StaticImage
              src="../../../images/afk/dream/croaker_3.webp"
              alt="Guides"
            />
            <p>Character information:</p>
            <ul className="bigger-margin">
              <li>
                <AFKCharacter mode="inline" slug="odie" enablePopover /> is the
                main carry,
              </li>
              <ul>
                <li>
                  {' '}
                  <AFKCharacter
                    mode="inline"
                    slug="lily-may"
                    enablePopover
                  />{' '}
                  can be used as an alternative
                </li>
              </ul>
              <li>
                <AFKCharacter mode="inline" slug="marilee" enablePopover /> is
                the secondary carry,
              </li>
              <li>
                <AFKCharacter mode="inline" slug="reinier" enablePopover /> acts
                as buffer and debuffer (<strong>Mythic+</strong> required),
              </li>
              <li>
                <AFKCharacter mode="inline" slug="thoran" enablePopover /> acts
                as a tank and debuffer
              </li>
              <li>
                <AFKCharacter
                  mode="inline"
                  slug="smokey-and-meerky"
                  enablePopover
                />{' '}
                acts as healer, buffer and secondary damage dealer,
              </li>
              <li>Artifacts:</li>
              <ul>
                <li>
                  <AFKItem name="Evocation Spell" /> best in slot for the
                  defensive and offensive buffs
                </li>
              </ul>
            </ul>
          </Tab>
          <Tab
            eventKey="Team4"
            title={
              <>
                <FontAwesomeIcon icon={faSkull} width="18" />
                Team #4 - No Celehypo Endless
              </>
            }
            className="with-bg"
          >
            <h5 className="endless">
              <FontAwesomeIcon icon={faSkull} width="18" /> Team #4 - No
              Celehypo Endless
            </h5>
            <StaticImage
              src="../../../images/afk/dream/croaker_4.webp"
              alt="Guides"
            />
            <p>Character information:</p>
            <ul className="bigger-margin">
              <li>
                <AFKCharacter mode="inline" slug="lily-may" enablePopover /> is
                the main carry,
              </li>
              <ul>
                <li>
                  {' '}
                  <AFKCharacter mode="inline" slug="odie" enablePopover /> can
                  be used as an alternative
                </li>
              </ul>
              <li>
                <AFKCharacter mode="inline" slug="marilee" enablePopover /> is
                the secondary carry,
              </li>
              <li>
                <AFKCharacter mode="inline" slug="korin" enablePopover />
                is the secondary carry and provides shields,
              </li>
              <li>
                <AFKCharacter mode="inline" slug="thoran" enablePopover /> acts
                as a tank and debuffer
              </li>
              <li>
                <AFKCharacter
                  mode="inline"
                  slug="smokey-and-meerky"
                  enablePopover
                />{' '}
                acts as healer, buffer and secondary damage dealer,
              </li>
              <li>Artifacts:</li>
              <ul>
                <li>
                  <AFKItem name="Evocation Spell" /> best in slot for the
                  defensive and offensive buffs.
                </li>
              </ul>
            </ul>
          </Tab>
        </Tabs>
        <SectionHeader title="Boss Mechanics" />
        <p>
          At the start of the battle, King Croaker will designate the player’s{' '}
          <strong>closest character as the “Tank”</strong> and will keep
          attacking that character, pausing to attack the Backline with a Ranged
          AOE attack from time to time, or to cast his Instakill Bubble on the
          Closest character. This allows us to choose between two different boss
          movesets, the <strong className="gold">Melee Tank Moveset</strong> and
          the <strong className="blue">Ranged Tank Moveset</strong>.
        </p>
        <ul>
          <li>
            Choosing a <strong className="gold">Melee Tank</strong> will cause
            the Boss to use his Melee AOE attack, damaging all Frontline
            attackers, and attack the Tank directly moderately with basic Melee
            attacks, along with the rest of his moveset,
          </li>
          <li>
            Choosing a <strong className="blue">Ranged Tank</strong> will cause
            the boss to use only his basic Ranged attack, but he will use it
            twice as often, while also using the rest of his moveset except for
            melee attacks, unless the Ranged Tank dies.
          </li>
        </ul>
        <SectionHeader title="Action Summary" />
        <p>
          King Croaker takes about <strong>30-34</strong> actions in the span of
          90 seconds (the full length of the battle - if you survive through all
          of it that is). Here's a list of all possible actions:
        </p>
        <h5>Melee Tank Moveset</h5>
        <ul>
          <li>Melee attack on Tank (up to 2 times per cycle)</li>
          <li>
            Ranged attack (up to 2 times per cycle – only if Melee Tank is far
            from the boss)
          </li>
          <li>
            Ranged AOE attack on area with most enemies, usually Backline (9x)
          </li>
          <li>Melee AOE knockback + stun attack on Frontline (6x)</li>
          <li>Instakill Bubble cast on Closest character (3x)</li>
        </ul>
        <h5>Ranged Tank Moveset</h5>
        <ul>
          <li>
            Melee attack (up to 2 times per cycle – only if Ranged Tank is
            dead.)
          </li>
          <li>Ranged attack on Tank (up to 4 times per cycle)</li>
          <li>
            Ranged AOE attack on area with most enemies, usually Backline (9x)
          </li>
          <li>
            Melee AOE knockback + stun attack on Frontline (only if Ranged Tank
            is dead.)
          </li>
          <li>Instakill Bubble cast on Closest character (3x)</li>
        </ul>
        <div className="fuse-ad-placeholder" data-fuse="22844297238"></div>
        <SectionHeader title="General Information" />
        <p>
          In all stages of the game, the number 1 choice of tank here will be
          <AFKCharacter mode="inline" slug="thoran" enablePopover />, because he
          can eat all the Instakill Bubble attacks by himself with his EX Weapon
          at Mythic rarity.{' '}
          <strong>
            He is the only one in the game who can bypass this boss ability.
          </strong>
        </p>
        <p>
          You will want constant healing, especially for your Backline in order
          to deal with the Ranged AOE attacks, which will quickly wipe out your
          entire Backline in the early stages of the game. This stage does not
          feature skill channeling interruption, so{' '}
          <AFKCharacter mode="inline" slug="smokey-and-meerky" enablePopover />{' '}
          is king here as they are able to keep buffing the team throughout the
          entire fight with little trouble while also healing the Backline.
        </p>
        <SectionHeader title="Videos" />
        <p>For more team idea, check this video:</p>
        <Row className="video">
          <Col xs={12} lg={6}>
            <YoutubeEmbed embedId="y_RQEo6JMgA" />
          </Col>
        </Row>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297238"></div>
    </DashboardLayout>
  );
};

export default AFKGuidesDreamCroaker;

export const Head: React.FC = () => (
  <Seo
    title="King Croaker | AFK Journey | Prydwen Institute"
    description="Dream Realm King Croaker guide that explains the boss mechanics and how to beat it!"
    game="afk"
  />
);
